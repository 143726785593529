import React from "react"
import Seo from "../components/seo"
import Contacts from "../components/contacts"
import Container from "../components/shared/policies/termsContainer"
import Text from "../components/shared/policies/textContainer"
import Title from "../components/shared/policies/title"
import Subtitles from "../components/shared/policies/subtitles"
import Paragraphs from "../components/shared/policies/paragraphs"
import NewFooter from "../components/shared/NewFooter"

function Dpa() {
  return (
    <>
      <Container>
        <Seo title="Data Processing Agreement" />
        <Title> PDF Pro Software Ltd. Data Processing Addendum</Title>
        <Text>
          <Paragraphs>
            <i>Last update: November 5 , 2021</i>
          </Paragraphs>
          <Paragraphs>
            This Data Processing Addendum (hereinafter referred to as the “
            <strong>DPA</strong>”) constitute a legal and binding agreement and
            an integral part of the terms and conditions (hereinafter referred
            to the “<strong>Terms</strong>”) entered into PDF Pro Software Ltd.,
            with registered office at 1383 W 8th Ave, Vancouver, BC V6H 3V9,
            Canada, business registration no. 700468291BC0001, including its
            subsidiaries, affiliates and contractors acting on its behalf
            (hereinafter collectively referred to as “<strong>We</strong>”, “
            <strong>Us</strong>” or “<strong>Our</strong>”) and Our clients,
            users and their heirs, agents, successors and assigns (hereinafter
            singularly and collectively referred to as “<strong>You</strong>” or
            “<strong>Your</strong>”), with reference to Your access and use of
            Our Platform and Services.
            <br />{" "}
            <strong>
              All terms with initial capital letters used herein shall have the
              meanings ascribed to them in the Terms, unless specifically
              defined herein
            </strong>
            .
          </Paragraphs>
          <Subtitles>SCOPE OF THIS DPA</Subtitles>
          <Paragraphs>
            The scope of this DPA is to detail the terms and conditions
            according to which, during the performance of Our Services, We will
            Process on Your behalf Personal Data of individuals from the
            European Economic Area, as provided in Sect. 5.3 of the Terms.
          </Paragraphs>
          <Subtitles>DEFINITIONS</Subtitles>
          <Paragraphs>
            In addition to the definitions in the Terms, the following
            definitions apply to the following capitalized terms in this DPA,
            provided that a word defined in or importing the singular number has
            the same meaning when used in the plural number, and vice versa:
          </Paragraphs>
          <Paragraphs>
            <strong>“Data Subject”</strong> means a natural person whose
            Personal Data are Processed for the Purpose in the context of the
            provision of Our Services as specified in the Terms.
          </Paragraphs>
          <Paragraphs>
            <strong>“EU Data Protection Law”</strong> means the European General
            Data Protection Regulation no. 2016/679 and its national
            implementing legislations.
          </Paragraphs>
          <Paragraphs>
            <strong>“Parties”</strong> means collectively We and You, while the
            “Party” means either Us or You.
          </Paragraphs>
          <Paragraphs>
            <strong>“Personal Data Breach”</strong> means a breach of security
            leading to the accidental or unlawful destruction, loss, alteration,
            unauthorized disclosure of, or access to, Personal Data transmitted,
            stored or otherwise Processed.
          </Paragraphs>
          <Paragraphs>
            “<strong>Processing</strong> ” or “<strong>Process</strong>” or “
            <strong>Processed</strong>” means any operation or set of operations
            which is performed on Personal Data or on sets of Personal Data,
            whether or not by automated means, such as collection, recording,
            organization, structuring, storage, adaptation or alteration,
            retrieval, consultation, use, disclosure by transmission,
            dissemination or otherwise making available, alignment or
            combination, restriction, erasure or destruction.
          </Paragraphs>
          <Paragraphs>
            <strong>“Purpose”</strong> means the provision of the Services and
            the associated Processing of Personal Data of individuals from the
            European Economic Area.
          </Paragraphs>
          <Paragraphs>
            <strong>“Sub-Processor”</strong> means the entity engaged by Us or
            any further sub-contractor to Process Personal Data on Your behalf
            and under Your instructions.
          </Paragraphs>
          <Subtitles>SUBJECT OF THIS DPA</Subtitles>
          <Subtitles size="medium" height="medium">
            General.
          </Subtitles>
          <Paragraphs>
            You appoint Us to Process Personal Data in Your interest and on Your
            behalf, in connection with the Purpose and in the context of the
            provision of the Services as specified in the Terms.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Subject of the Processing.
          </Subtitles>
          <Paragraphs>
            The subject matter of the Processing will cover the following
            types/categories of Personal Data: (<strong>i</strong>) contact data
            (e.g., name, email, address, etc.); (<strong>ii</strong>) private
            keys; (<strong>iii</strong>) IP address; (<strong>iv</strong>) order
            information (i.e., products purchased, license types, time of
            purchase). The categories aforementioned can be reduced according to
            Your instructions.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Compliance with the law and liability towards Data Subjects.
          </Subtitles>
          <Paragraphs>
            The Parties undertake to comply with the applicable data protection
            laws in all their actions and operations related to the Processing
            of Personal Data and, in particular, with the EU Data Protection
            Law. The Parties agree that they will be liable to Data Subjects for
            the entire damage resulting from a violation of the EU Data
            Protection Law. If one Party paid full compensation for the damage
            suffered, it is entitled to claim back from the other Party that
            part of the compensation corresponding to the other Party’s part of
            responsibility for the damage. For such purpose, You agree to be
            liable to Data Subjects for the entire damage resulting from a
            violation of EU Data Protection Law with regard to Processing of
            Personal Data for which You are a “controller” (as defined under the
            EU Data Protection Law; i.e., You determine the purposes and means
            of the Processing of Personal Data), and that We will only be liable
            to Data Subjects for the entire damage resulting from a violation of
            the obligations of EU Data Protection Law directed to Us or where We
            acted outside of or contrary to Your lawful instructions. We will be
            exempt from liability if We prove that We are not in any way
            responsible for the event giving rise to the damage.
          </Paragraphs>
          <Subtitles>OUR OBLIGATIONS</Subtitles>
          <Subtitles size="medium" height="medium">
            Purpose and Your instructions.
          </Subtitles>
          <Paragraphs>
            We shall only process Personal Data on Your behalf, according to
            Your instruction and not for any other purposes than the Purpose.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Technical and organizational measures.
          </Subtitles>
          <Paragraphs>
            We warrant and undertake to maintain appropriate and sufficient
            technical and organizational security measures to protect the
            Personal Data against accidental or unlawful destruction or
            accidental loss, damage, alteration, unauthorized disclosure or
            access, and against all other unlawful forms of Processing. Such
            measures shall be at least the measures set out in Our Privacy
            Policy (available at _link_) and shall include, without limitation,
            physical access control, logical access control (i.e., non-physical
            access control measures such as passwords), and data encryption. We
            can provide You, upon request, with adequate proof of compliance of
            the foregoing.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Personal Data Breach notification.
          </Subtitles>
          <Paragraphs>
            We will notify You, without undue delay, after becoming aware of a
            Personal Data Breach, taking reasonable steps to mitigate the
            effects and to minimize any damage resulting from the Personal Data
            Breach. In particular, at the latest within 72 (seventy two) hours
            upon the technical discovery of such breach of Personal Data, We
            will provide You with at least the following information: (
            <strong>i</strong>) the nature of the Personal Data Breach
            including, where possible, the categories and approximate number of
            Data Subjects concerned and the categories and approximate number of
            Personal Data records concerned; (<strong>ii</strong>) the likely
            consequences of the Personal Data Breach; (<strong>iii</strong>) the
            measures taken or proposed to be taken to address the Personal Data
            Breach, including, where appropriate, measures to mitigate its
            possible adverse effects.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Confidentiality.
          </Subtitles>
          <Paragraphs>
            We ensure that all persons authorized to Process the Personal Data
            have committed themselves to confidentiality or are under an
            appropriate statutory obligation of confidentiality.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Assistance and cooperation.
          </Subtitles>
          <Paragraphs>
            We will promptly inform You if, in Our opinion, Your instructions
            infringe EU Data Protection Law, or if We are unable to comply with
            Your instructions. At Your expenses and subject to a separately
            agreed price quotation, We will provide You with reasonable
            assistance in complying with data security, data breach
            notifications, data protection impact assessments, and prior
            consultations with supervisory authorities requirements under EU
            Data Protection Law, taking into account the nature of the
            Processing and the information available to Us.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Data Subjects’ rights.
          </Subtitles>
          <Paragraphs>
            We shall forward to You of all requests of Data Subjects concerning
            the exercising of the Data Subjects’ rights under the EU Data
            Protection Law and, at Your expenses and subject to a separately
            agreed price quotation, We will provide You with reasonable
            assistance in the consequent actions required, as provided by the EU
            Data Protection Law. You understand and agree that certain requests
            concerning exercising of Data Subjects’ rights may require Us to
            directly reply to Data Subjects.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Deletion of the Personal Data.
          </Subtitles>
          <Paragraphs>
            We shall, in accordance with Your choice, delete or return all
            Personal Data to You after the end of the provision of the Services
            relating to the Processing of Personal Data and delete existing
            copies. In particular, following the termination of the Terms,
            Personal Data linked to Your account will be retained for a period
            of ten (10) days from such termination within which You may contact
            Us to export such Data; beyond such term, We are authorized to
            delete all Personal Data in the normal course of operation, unless
            We are required by any applicable Laws to retain some or all of such
            Personal Data (which in any case shall be securely protected from
            any further Processing except to the extent required by such Laws).
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Sub-Processors.
          </Subtitles>
          <Paragraphs>
            You give Us a general authorization to disclose Personal Data to
            Sub-Processors for the Purpose; We represent and warrant that such
            Sub-Processors have provided Us sufficient guarantees to implement
            appropriate technical and organizational measures to ensure that
            their sub-Processing will meet requirements under EU Data Protection
            Law, to the extent applicable to the nature of the service provided
            by such Sub-Processors. Our current Sub-Processor is Amazon Web
            Services Inc. In case of intended addition or replacement of
            Sub-Processors, You are entitled to object to such changes by
            notifying Us the reasonable grounds for such objection.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Personal Data transfers.
          </Subtitles>
          <Paragraphs>
            The Personal Data will be Processed in Canada, Belgium and Germany,
            and any transfer of such Personal Data outside of the European
            Economic Area shall be subject both to Your consent and to separate
            contractual arrangements between Us and such third-party data
            Sub-Processors, binding them to comply with obligations in
            accordance with the EU Data Protection Law.
          </Paragraphs>
          <Subtitles>YOUR OBLIGATIONS</Subtitles>
          <Paragraphs>
            You represent and warrant that, in relation to the Processing of
            Personal Data for the Purposes in the context of the Services, You
            act as a “controller” (as defined under the EU Data Protection Law;
            i.e., You determine the purposes and means of the Processing of
            Personal Data) and that: (<strong>i</strong>) You comply with EU
            Data Protection Law when Process Personal Data, and only gives Us
            lawful instructions; (<strong>ii</strong>) Data Subjects have been
            informed of Our Processing of their Personal Data as required by EU
            Data Protection Law; (<strong>iii</strong>) there is a valid legal
            ground for the processing of Personal Data under EU Data Protection
            Law; (<strong>iv</strong>) You shall not disclose, and shall not
            permit any Data Subject to disclose, to Us for Processing any
            special categories of personal data (as defined under the EU Data
            Protection Law; i.e., personal data revealing racial or ethnic
            origin, political opinions, religious or philosophical beliefs,
            trade union membership, genetic data, biometric data, data
            concerning health or data concerning sex life or sexual
            orientation); (<strong>v</strong>) You shall comply with Data
            Subjects’ requests to exercise their rights of access,
            rectification, erasure, data portability, restriction of Processing,
            and objection to the Processing, and rights relating to automated
            decision-making; (<strong>vi</strong>) You shall comply with data
            accuracy, proportionality and data retention principles; (
            <strong>vii</strong>) You shall implement appropriate technical and
            organizational measures to ensure, and to be able to demonstrate,
            that the Processing of Personal Data is performed in accordance with
            EU Data Protection Law; and (<strong>viii</strong>) You will
            cooperate with Us to fulfill our respective data protection
            compliance obligations in accordance with EU Data Protection Law
          </Paragraphs>
          <Subtitles>TERM AND TERMINATION</Subtitles>
          <Subtitles size="medium" height="medium">
            Term.
          </Subtitles>
          <Paragraphs>
            This DPA shall commence on the first day the Terms are effective and
            shall continue in full force and effect until the termination of the
            Purpose.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Termination in case of breach.
          </Subtitles>
          <Paragraphs>
            This DPA may be immediately terminated by a Party if the other Party
            breaches any material obligation of this DPA and fails to remedy
            such breach within 10 (ten) days after receiving a written notice
            from the non-defaulting Party requiring it to remedy the breach.
          </Paragraphs>
          <Subtitles>MISCELLANEOUS</Subtitles>
          <Subtitles size="medium" height="medium">
            Independent entities.
          </Subtitles>
          <Paragraphs>
            We are an independent contractor, so no partnership, joint venture,
            or agency relationship exists between Us and You. You and Us are
            each responsible for paying our own employees, including employment
            related taxes and insurance.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Force majeure.
          </Subtitles>
          <Paragraphs>
            We shall not be responsible for any failure to perform due to
            unforeseen circumstances or to causes beyond Our reasonable control,
            including but not limited to acts of God, war, riot, embargoes, acts
            of civil or military authorities, fire, floods, accidents, strikes,
            lockouts, or shortages of transportation, facilities, fuel, energy,
            labor or materials. In the event of any such delay, We will be
            excused from such performance to the extent it is delayed or
            prevented by such cause. In any case, if such an event were to
            occur, we would notify You.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            No implied waiver.
          </Subtitles>
          <Paragraphs>
            Any tolerance or silence by Us, even if on a continuous and
            reiterated basis, in relation to any breach or default by You of any
            provision of this DPA shall not be considered as a consent to such
            breaches and defaults and shall not affect the validity of the
            clause breached nor shall be construed as a waiver to any of the
            rights pertaining to Us pursuant to this DPA and/or the applicable
            Laws.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Governing law and arbitration.
          </Subtitles>
          <Paragraphs>
            This DPA is governed by the substantive and procedural Laws of
            Canada, without regard to conflict of laws provisions. Any dispute
            arising out of, or relating to, this DPA shall be settled through
            arbitration conducted by a single neutral arbitrator and with place
            in Vancouver, British Columbia, Canada. The arbitrator’s decision
            and award shall be final and binding, with some exceptions under the
            Canadian Arbitration Act, and judgment on the award may be entered
            in any court having jurisdiction. To such purpose, We and You agree
            that: (<strong>i</strong>) if We and/or You want to arbitrate a
            dispute, a written notice will be sent to the other party with a
            description of the dispute, all documents/information, and the
            proposed resolution; (<strong>ii</strong>) We and You will make
            attempts to resolve the conflict and, if the dispute is not resolved
            within forty-five (45) days after receipt of the notice of
            arbitration, then the dispute will be submitted to formal
            arbitration; (<strong>iii</strong>) We and You will not to pursue
            arbitration broadly and that any arbitration will be solely between
            You and Us (not brought on behalf of or together with the statement
            of another person), provided that if for any reason a Court or
            arbitrator holds that this restriction is unacceptable or
            unenforceable, then this agreement to arbitrate does not apply and
            the dispute and must be brought to the competent Canadian Courts; (
            <strong>iv</strong>) We and You will be responsible for the
            respective costs relating to counsel, experts, and witnesses, as
            well as any other expenses related to arbitration; (
            <strong>v</strong>) We and You may bring qualifying claims in small
            claims court.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Remaining matters and prevalence.
          </Subtitles>
          <Paragraphs>
            All matters not provided in this DPA shall be regulated by the
            Terms, which shall prevail in every case of discrepancy between the
            content of this DPA and the same Terms.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            Entire agreement.
          </Subtitles>
          <Paragraphs>
            This DPA constitute the entire agreement between We and You with
            respect to its subject matter and it supersedes all prior or
            contemporaneous agreements concerning such matter (including, but
            not limited to, any prior versions of this DPA).
          </Paragraphs>
        </Text>
      </Container>
      <NewFooter />
    </>
  )
}

export default Dpa
